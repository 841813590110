/** @jsx jsx */
import { jsx, Button, Flex, Box, Container, Styled, Grid } from 'theme-ui'
import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from "../components/seo"
import BlockContent, { defaultSerializers } from '@sanity/block-content-to-react'

const Breed = ({data}) => {

  const breed = data.breed
  const breeds = data.breeds.edges


  return (
    <section>
      <SEO title={breed.title} />
      <Container sx={{px: 4, mt: 5, maxWidth: 'max'}}>
        <Flex>
        <small><Styled.a as={Link} to={`/breeds`}>Breeds</Styled.a> <span>{`>`}</span> <Styled.a as={Link} to={`/breed-groups/${breed.slug.current}`}>{breed.title}</Styled.a></small>
        </Flex>
        <Flex sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <Styled.h1 sx={{mb: '1'}}>{breed.title}</Styled.h1>
            <div sx={{display: 'inline-block'}}>
              <Styled.h6 sx={{backgroundColor: '#f5f5f5', borderRadius: '99px', px: 5, py: 3}}>{breed.title}</Styled.h6>
            </div>
          </Box>
          <Button variant='rounded' sx={{display: ['none','none','block']}}>+ Follow</Button>
        </Flex>

        <Grid columns={[1,1,3,4]}>
          {breeds.map(({node,i}) => (
            <Box sx={{p: 5, boxShadow: 'medium', backgroundColor: '#f5f5f5', borderRadius: '5px',}}>
              <Styled.a as={Link} to={`/breeds/${node.slug.current}`}>{node.title}</Styled.a>
            </Box>
          ))}
      
        </Grid>
      </Container>
    </section>
  )
}

export default Breed

export const query = graphql`
  query($slug: String!) {
    breed: sanityBreedGroup(slug: {current: {eq: $slug}}) {
      id
      title
      slug {
        current
      }
    }

    breeds: allSanityBreed(filter: {breedGroups: {slug: {current: {eq: $slug}}}}) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }

  }
`